<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('market.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>

            <b-tab :title="$t('market.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:product-categories-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

import InitFileManager from '@/components/InitFileManager'

export default {
  components: {
    InitFileManager,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
      extensionOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/admin/categories', {
      params: {
        type: 'product',
      },
    })
      .then(response => {
        this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
      })

    await this.$http.get(`/api/admin/categories/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'product-categories-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        position: this.item.position,
        type: 'product',
        status: this.item.status,
        description: this.item.description,
      }

      this.$http.put(`/api/admin/categories/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'admin:product-categories-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        position: item.position,
        status: item.status,
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
      })

      return data
    },
  },
}
</script>
